import React from "react"
import styled from "styled-components"
import HTMLHeader from "../components/htmlHeader"

const Content = styled.div`
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  margin: 60px 0 0 0;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
  }
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 36px;
  }
`

const NotFoundPage = () => (
  <>
    <HTMLHeader />
    <Content>
      <TitleContainer>
        <Title>404 Page</Title>
      </TitleContainer>
    </Content>
  </>
)

export default NotFoundPage
